import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import InnerHero from '../components/InnerHero';
import Hero from '../images/contact--header@2x.jpg';
import ContactForm from '../components/ContactForm';

const Contact = () => (
  <Layout>
    <SEO
      title="Contact Us"
      lang="en"
      meta={[]}
    />

    <InnerHero image={Hero} tagline="Still Have Questions? Get In Touch With Us." />

    <div className="container--md">
      <section className="row">
        <div className="col-12">
          <h2>
            <strong>
              {'Contact Us'}
            </strong>
            <br />
            <br />
          </h2>
        </div>
        <div className="col-12">
          <ContactForm />
          <br />
          <br />
        </div>

        {/*
        <div className="col-12 col-sm-12 col-md-7 col-lg-7">
          <iframe title="seekersMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114018.30842151608!2d-80.19925129280001!3d26.74207396774542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d8d5ccb595adc1%3A0x15efc7b51fe16bde!2sWest%20Palm%20Beach%2C%20FL!5e0!3m2!1sen!2sus!4v1578071704428!5m2!1sen!2sus" width="100%" height="275" frameBorder="0" />
        </div>
        <div className="col-12 col-sm-12 col-md-1 col-lg-1" />
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 font--14 lh--md">
          <strong>
            {'Upendo Staffing, a South Florida Talent Agency'}
          </strong>
          <br />
          {'Serving West Palm Beach, Miami, Fort Lauderdale, and surrounding areas!'}
          <br />
          <br />

          <i className="fab fa-twitter" />
          <a
            href="https://twitter.com/StaffingUpendo"
            target="_blank"
            rel="noopener noreferrer"
            className="font--upendoPrimary"
          >
            {' @upendo-staffing'}
          </a>
          <br />

          <i className="fab fa-linkedin" />
          <a
            href="https://www.linkedin.com/in/david-ruzga-71168a6a/"
            target="_blank"
            rel="noopener noreferrer"
            className="font--upendoPrimary"
          >
            {' @david-ruzga'}
          </a>
          <br />

          <i className="fab fa-instagram" />
          <a
            href="https://www.instagram.com/upendo_staffing/"
            target="_blank"
            rel="noopener noreferrer"
            className="font--upendoPrimary"
          >
            {' upendo_staffing'}
          </a>
          <br />

          <i className="fas fa-phone" />
          <a
            href="tel:5615188908"
            target="_blank"
            rel="noopener noreferrer"
            className="font--upendoPrimary"
          >
            {' 561-518-8908'}
          </a>
          <br />

          <i className="fas fa-envelope-open-text" />
          <a
            href="mailto:info@upendostaffing.com"
            target="_blank"
            rel="noopener noreferrer"
            className="font--upendoPrimary"
          >
            {' info@upendostaffing.com'}
          </a>
          <br />


          <i className="fas fa-clipboard-list" />
          <Link
            to="/jobs"
            className="font--upendoPrimary"
          >
            {' View Open Jobs'}
          </Link>
        </div>
      */}
      </section>
    </div>
    <br />
    <br />
  </Layout>
);

export default Contact;
