import React, { useState } from 'react';

import {
  Input,
  TextArea,
  maskPhoneNumber,
  validateEmail,
} from '@nerds-with-charisma/nerds-style-sass';

const ContactForm = () => {
  const [isInvalid, isInvalidSetter] = useState(true);

  const [name, nameSetter] = useState(null);
  const [nameError, nameErrorSetter] = useState(null);
  const [email, emailSetter] = useState(null);
  const [emailError, emailErrorSetter] = useState(null);
  const [phone, phoneSetter] = useState(null);
  const [message, messageSetter] = useState(null);
  const [messageError, messageErrorSetter] = useState(null);
  const [company, companySetter] = useState(null);
  const [companyError, companyErrorSetter] = useState(null);
  const [reason, reasonSetter] = useState(null);
  const [reasonError, reasonErrorSetter] = useState(null);
  const [services, servicesSetter] = useState(null);
  const [servicesError, servicesErrorSetter] = useState(null);

  const handleValidation = () => {
    let hasErrors = true;

    if (!name || name.length < 2) {
      nameErrorSetter('Enter a valid name');
      hasErrors = true;
    } else {
      nameErrorSetter(null);
      hasErrors = false;
    }

    if (!email || email.length < 2 || validateEmail(email).error === true) {
      emailErrorSetter('Enter a valid email');
      hasErrors = true;
    } else {
      emailErrorSetter(null);
      hasErrors = false;
    }

    if (!message || message.length < 2) {
      messageErrorSetter('Enter a valid message');
      hasErrors = true;
    } else {
      messageErrorSetter(null);
      hasErrors = false;
    }

    if (company && company.length < 2) {
      companyErrorSetter('Please enter a full company name');
      hasErrors = true;
    } else {
      companyErrorSetter(null);
      hasErrors = false;
    }

    if (reason && reason.length < 2) {
      reasonErrorSetter('Please enter a reason for contacting us');
      hasErrors = true;
    } else {
      reasonErrorSetter(null);
      hasErrors = false;
    }

    if (services && services.length < 2) {
      servicesErrorSetter('Please enter a valid service');
      hasErrors = true;
    } else {
      servicesErrorSetter(null);
      hasErrors = false;
    }

    if (hasErrors === false) {
      isInvalidSetter(false);
    } else {
      isInvalidSetter(true);
    }
  };

  return (
    <form
      id="ContactForm"
      name="ContactForm"
      data-netlify="true"
      method="post"
      className="row"
    >
      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        <Input
          title="Name"
          id="input--name"
          callback={(v) => nameSetter(v)}
          error={nameError}
          type="text"
          required
          populatedValue={name}
          blurCallback={handleValidation}
        />

        <br />
        <br />
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        <Input
          title="Email"
          id="input--email"
          callback={(v) => emailSetter(v)}
          error={emailError}
          type="email"
          required
          populatedValue={email}
          blurCallback={handleValidation}
        />

        <br />
        <br />
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        <Input
          title="Phone"
          id="input--maskedPhone"
          callback={(v) => {
            phoneSetter(maskPhoneNumber(v));
            document.getElementById('input--maskedPhone').value = maskPhoneNumber(v);
          }}
          type="tel"
          placeHolder="(555) 555-5555"
          maxLength="14"
          minLength="14"
          populatedValue={phone}
          blurCallback={handleValidation}
        />

        <br />
        <br />
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        <Input
          title="Company"
          id="input--company"
          callback={(v) => companySetter(v)}
          error={companyError}
          type="text"
          populatedValue={reason}
          blurCallback={handleValidation}
        />

        <br />
        <br />
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        <Input
          title="Reason for Contacting"
          id="input--reason"
          callback={(v) => reasonSetter(v)}
          error={reasonError}
          type="text"
          populatedValue={company}
          blurCallback={handleValidation}
        />

        <br />
        <br />
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        <Input
          title="Services Interested In"
          id="input--services"
          callback={(v) => servicesSetter(v)}
          error={servicesError}
          type="text"
          populatedValue={services}
          blurCallback={handleValidation}
        />

        <br />
        <br />
      </div>

      <div className="col-12">
        <TextArea
          title="Message"
          id="input--message"
          callback={(v) => messageSetter(v)}
          error={messageError}
          required
          populatedValue={message}
          rows="5"
          blurCallback={handleValidation}
        />

        <br />
        <br />
      </div>

      <div className="col-12 text--right">
        <button
          type="submit"
          disabled={isInvalid}
          className="btn bg--upendoPrimary float--right radius--lg border--none font--18"
        >
          <strong>
            {'Submit Your Inquiry'}
          </strong>
        </button>
      </div>

      <input type="hidden" name="form-name" value="ContactForm" />
    </form>
  );
};

export default ContactForm;
